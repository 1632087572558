<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-08-02 14:43:02
 * @LastEditTime: 2022-09-07 16:50:03
 * @LastEditors: cyy
 * @Description: 图文-已购用户
 * @FilePath: \teacherdingding.com\src\views\graphic\components\memberList.vue
-->
<template>
  <div class="memberList">
    <div class="top">
      <div class="add">
        <el-button
          type="primary"
          size="medium"
          style="width:130px"
          @click="statusAddMember = true"
        >
          添加用户
        </el-button>
      </div>
      <div class="search">
        <el-form
          ref="ruleIngredientsForm"
          label-width="100px"
          :model="search"
          class="flex-center"
          size="small"
          :rules="rules"
        >
          <el-form-item label="关键词" class="mr20">
            <div class="search-item search-item1 ">
              <div class="vm select">
                <el-select v-model="search.search_type">
                  <el-option
                    v-for="item in options1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="vm input">
                <el-input
                  :placeholder="placeholder"
                  v-model="search.search"
                  clearable
                ></el-input>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="购买/添加时间" class="mr20">
            <div class="vm select ">
              <el-date-picker
                style="width:340px"
                v-model="search.start_c"
                @change="
                  selectedTime(arguments[0], ['start_c_time', 'end_c_time'])
                "
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                unlink-panels
                value-format="timestamp"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </div>
          </el-form-item>

          <el-form-item label="类型" class="mr20">
            <div class="vm select ">
              <el-select v-model="search.type" style="width:300px">
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="失效时间" class="mr20">
            <div class="vm select ">
              <el-date-picker
                style="width:340px"
                v-model="search.expiration_time"
                @change="
                  selectedTime(arguments[0], [
                    'start_expiration_time',
                    'end_expiration_time',
                  ])
                "
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                unlink-panels
                value-format="timestamp"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </div>
          </el-form-item>

          <!-- <el-form-item label="剩余有效期" prop="large_valid_days">
            <div class="search-item8">
              <div class="vm select">
                <div class="flex-center" style="width:100%">
                  <el-input
                    size="small"
                    :value="search.small_valid_days"
                    @input="
                      search.small_valid_days = toNumber(arguments[0], true)
                    "
                    clearable
                  >
                    <template slot="append">天</template>
                  </el-input>
                  <span class="middle-space">至</span>
                  <el-input
                    size="small"
                    :value="search.large_valid_days"
                    @input="
                      search.large_valid_days = toNumber(arguments[0], true)
                    "
                    clearable
                  >
                    <template slot="append">天</template>
                  </el-input>
                </div>
              </div>
            </div>
          </el-form-item> -->
        </el-form>
      </div>

      <el-button size="medium" type="primary" @click="toSearch">搜索</el-button>
    </div>

    <pagination2
      :option="search2"
      class="childDialogBox"
      url="/Graphic/userList"
      ref="childDialogBox"
    >
      <template v-slot:default="{ tableData }">
        <el-table
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          :data="tableData"
        >
          <el-table-column label="用户" fixed min-width="150px">
            <template slot-scope="{ row }">
              <div class="user_contain">
                <div class="tu">
                  <img :src="row.uphoto" alt="" />
                </div>
                <div class="name">{{ row.uname }}</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="手机号">
            <template slot-scope="{ row }">{{ row.umobile || '-' }}</template>
          </el-table-column>

          <el-table-column label="类型">
            <template slot-scope="{ row }">
              {{ row.type == 1 ? '自主购买 ' : '手动添加' }}
            </template>
          </el-table-column>

          <el-table-column label="购买/添加时间" min-width="160px">
            <template slot-scope="{ row }">
              {{ row.c_time | formatTimeStamp }}
            </template>
          </el-table-column>

          <el-table-column label="失效时间" min-width="160px">
            <template slot-scope="{ row }">
              <!-- <span v-if="row.validity_type == 1">永久有效</span>
              <span v-else-if="row.validity_type == 2">
                {{ row.validity }}天
              </span> -->
              <span v-if="row.expiration_type == 1">
                {{ row.expiration_time | formatTimeStamp }}
              </span>
              <span v-else>永久有效</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" fixed="right" min-width="150px">
            <template slot-scope="{ row }">
              <!--编辑有效期  |  作废 -->
              <el-button type="text" @click="modifyTime(row)">
                修改有效期
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" @click="del(row)">
                作废
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </pagination2>

    <!-- 修改有效期 -->
    <el-dialog
      title="修改有效期"
      :close-on-click-modal="false"
      :visible.sync="validStatus"
      width="442px"
    >
      <div class="dialog_body">
        <el-row>
          <el-col :span="8">学员：</el-col>
          <el-col :span="16">
            <span style="color: #333333">{{ changetiem.uname }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">图文：</el-col>
          <el-col :span="16">
            <span style="color: #333333">{{ memberHeader.name }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">订单有效期：</el-col>
          <el-col :span="16">
            <span class="redcolor">
              <span v-if="changetiem.expiration_type == 1">
                {{ changetiem.expiration_time | formatTimeStamp }}
              </span>
              <span v-else>永久有效</span>

              <!-- {{
                changetiem.expiration_time | formatTimeStamp('yyyy-MM-dd hh:mm')
              }} -->
            </span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">剩余：</el-col>
          <el-col :span="16">
            <span v-if="changetiem.expiration_type == 1">
              <span class="redcolor" v-if="changetiem.days_remaining > 0">
                {{ changetiem.days_remaining }}天
              </span>
              <span class="redcolor" v-else>
                {{ changetiem.days_remaining }}天(已过期)
              </span>
            </span>
            <span v-else>永久有效</span>
          </el-col>
        </el-row>
        <el-row align="middle" type="flex">
          <el-col :span="8">修改有效期：</el-col>
          <el-col :span="16">
            <el-date-picker
              style="width: 258px"
              @change="editpicker"
              popper-class="graphic-pick-time"
              v-model="validEndtime"
              type="datetime"
              format="yyyy-MM-dd HH:mm"
              value-format="timestamp"
              placeholder="点击选择"
              :picker-options="pickerOptions"
            ></el-date-picker>
            <span
              class="redcolor ml20"
              v-if="editpicker_surplus > 0"
              style="line-height: 40px"
            >
              剩余{{ editpicker_surplus }}天
            </span>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="validStatusCancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" size="medium" @click="validStatusSubmit">
          确 定
        </el-button>
      </span>
    </el-dialog>

    <!-- 添加用户 -->
    <addmember
      v-if="statusAddMember"
      :type="5"
      :dialogstatus.sync="statusAddMember"
      @addmemberList="addmemberList"
    ></addmember>
  </div>
</template>

<script>
import addmember from '@cm/base/addmember'
export default {
  name: 'memberList',

  components: {
    addmember,
  },

  data() {
    var validatePass2 = (rule, value, callback) => {
      if (
        typeof this.search.large_valid_days === 'number' &&
        this.search.large_valid_days < this.search.small_valid_days
      ) {
        this.tosearch = false
        callback(new Error('最大天数不能小于最小天数'))
      } else {
        this.tosearch = true
        callback()
      }
    }
    return {
      pickerOptions: {
        disabledDate(time) {
          //time跟页面没有关系   相当于日期数组
          return time.getTime() < Date.now() //设置选择今天以及今天之后的日期
        },
      },

      editpicker_surplus: 0,

      fieldsList: [],

      changetiem: {},

      statusAddMember: false,

      validEndtime: '',

      validStatus: false,

      rules: {
        large_valid_days: [{ validator: validatePass2, trigger: 'blur' }],
      },

      search2: {},

      search: {
        graphic_id: '',
        start_c: '',
        expiration_time: '',
        search_type: 1,
        search: '',
        start_c_time: '',
        end_c_time: '',
        start_expiration_time: '',
        end_expiration_time: '',
      },

      options2: [
        { label: '全部', value: 0 },
        { label: '自主购买', value: 1 },
        { label: '手动添加', value: 2 },
      ],

      // 搜索-
      options1: [
        { label: '用户名', value: 1, placeholder: '输入用户名' },
        { label: '手机号', value: 2, placeholder: '输入手机号' },
      ],
    }
  },

  props: {
    graphic_id: {
      type: String,
      default: undefined,
    },
    memberHeader: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    // 昵称 真实姓名 手机号码 切换
    placeholder() {
      return this.options1.find(item => item.value == this.search.search_type)
        .placeholder
    },
  },

  created() {
    this.search2 = _.assign({}, this.search, { graphic_id: this.graphic_id })
  },

  methods: {
    editpicker(val) {
      var time = new Date().valueOf()
      this.editpicker_surplus = Math.ceil((val - time) / (1000 * 60 * 60 * 24))
      this.validity3 = val / 1000
    },

    toSearch() {
      this.search2 = _.assign({}, this.search, { graphic_id: this.graphic_id })
    },

    async addmemberList(val) {
      const ids = val.map(item => item.uid)
      if (ids.length > 0) {
        await this.$http({
          url: '/Graphic/addUser',
          data: {
            graphic_id: this.graphic_id,
            uids: ids,
          },
          successMsg: true,
        })
        this.$refs.childDialogBox.reset()
      }
      this.statusAddMember = false
    },

    //确认修改有效期
    async validStatusSubmit() {
      if (this.validEndtime) {
        await this.$http({
          url: '/Graphic/setExpiration',
          data: {
            graphic_user_id: this.changetiem.graphic_user_id,
            expiration_time: this.validEndtime / 1000,
          },
        })
        this.$refs.childDialogBox.reset()
      }

      this.validStatusCancel()
    },

    // 有效期弹窗取消
    validStatusCancel() {
      this.validEndtime = ''
      this.changetiem = {}
      this.validStatus = false
    },

    modifyTime(row) {
      this.changetiem = row
      this.editpicker_surplus = 0
      this.validEndtime = row.expiration_time * 1000 || ''
      this.$nextTick(() => {
        this.validStatus = true
      })
    },

    // 作废
    async del(row) {
      this.$confirm('是否确定作废？', '作废', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/Graphic/delUser',
            data: {
              graphic_user_id: row.graphic_user_id,
            },
            successMsg: true,
          })
          this.$refs.childDialogBox.reset()
        })
        .catch(() => {})
    },

    // 选中时间
    selectedTime(value, contextName) {
      const newVal = value || [0, 0]
      newVal.forEach((item, index) => {
        this.search[contextName[index]] = item / 1000
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.memberList {
  .dialog_body {
    .el-row {
      margin-bottom: 20px;
    }
    ::v-deep.el-row--flex.is-align-middle {
      align-items: flex-start;
    }
  }
  ::v-deep .el-dialog__header {
    padding-bottom: 0;
  }
  ::v-deep .el-dialog__body {
    padding-top: 20px;
  }
  .top {
    margin-bottom: 28px;

    .search {
      margin-top: 28px;
      .middle-space {
        font-size: 14px;
        line-height: 19px;
        margin: 0 10px;
        color: rgba(51, 51, 51, 1);
      }
      ::v-deep .el-form {
        flex-wrap: wrap;
      }
      .search_type {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .el-form-item__content {
          height: auto;
        }
        .el-form-item {
          width: 33.3%;
          margin-bottom: 20px;
        }
      }
      ::v-deep .el-input,
      ::v-deep .select {
        width: 100%;
        .el-input__inner {
          width: 100%;
        }
      }
      ::v-deep .search-item1 {
        display: flex;
        width: 300px;
        .select {
          width: 130px;
          input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .input {
          width: 100%;
          input {
            border-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
      ::v-deep .search-item2,
      ::v-deep .search-item6 {
        .select {
          width: 160px;
        }
      }
      ::v-deep .search-item3,
      ::v-deep .search-item4 {
        .el-date-editor {
          width: 292px;
        }
        .el-input__icon {
          line-height: 27px;
        }
      }
      ::v-deep .search-item5,
      ::v-deep .search-item7,
      ::v-deep .search-item8 {
        width: 300px;
        .el-input {
          // width: 130px;
          position: relative;
          input[type='number'] {
            padding-right: 50px;
          }
          .el-input-group__append {
            position: absolute;
            right: 0;
            background: none;
            top: 6px;
            height: 100%;
            border: 0;
            // line-height: 36px;
            width: auto;
            padding: 0;
            padding-right: 10px;
          }
        }
      }
    }
  }
  .childDialogBox {
    .user_contain {
      display: flex;
      align-content: center;
      .tu {
        width: 28px;
        height: 28px;
        background: #939191;
        border-radius: 14px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
      }
    }
  }
}
</style>
<style lang="scss">
.graphic-pick-time {
  .el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
    display: none !important;
  }
}
</style>
