<template>
  <div id="completedCadets">
    <template>
      <div class="all">
        <div class="search">
          <el-button
            @click="exportTable"
            class="mr10"
            type="primary"
            size="medium"
          >
            导出
          </el-button>

          <el-input
            v-model="search"
            placeholder="输入学员名称搜索"
            style="width: 218px"
            size="medium"
          ></el-input>
        </div>

        <div class="table-contain">
          <el-table
            :data="orderlist"
            sortable="custom"
            @sort-change="sortChange"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
              padding: '0',
              height: '48px',
            }"
          >
            <el-table-column label="填写学员">
              <template slot-scope="{ row }">
                <div class="course-info">
                  <div class="cover">
                    <lazy class="img" :src="row.uphoto"></lazy>
                  </div>
                  <div class="right">
                    <div class="title">{{ row.uname }}</div>
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="填写时间" sortable>
              <template slot-scope="{ row }">
                <div v-if="row.submit_time == 0">--</div>
                <div v-if="row.submit_time != 0">
                  {{ row.submit_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="{ row }">
                <div class="btns">
                  <span class="text" @click="detail(row.submit_id)">查看</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- <div :style="`height:${emptyStatusHeihgt}px`" v-if="isNoData" style="margin-left:15px">
      <empty :isOld="false" :positionCenter="true"></empty>
    </div> -->
        <loading v-show="isLoading" leftPos="0"></loading>

        <!--分页-->
        <pagination
          :currPage="currentPage"
          :total="Number(total)"
          @currentChange="pageCodeChange"
        ></pagination>

        <!-- 查看的弹出啊 -->
        <el-dialog
          :title="naire_name"
          :visible.sync="dialogTableVisible"
          :close-on-click-modal="false"
          width="40%"
          top="6vh"
        >
          <div class="zi1">{{ description }}</div>
          <div class="tow">
            <div class="left">
              <div class="tu1"><img :src="uphoto" /></div>
              <div class="zi2">{{ uname }}</div>
            </div>
            <div class="right" v-if="submit_time != 0">
              填写时间： {{ submit_time | formatTimeStamp('yyyy-MM-dd hh:mm') }}
            </div>
            <div class="right" v-if="submit_time == 0">--</div>
          </div>
          <div class="clear"></div>
          <div class="auto">
            <div class="bottom" v-for="(i, index) in list" :key="index">
              <dl v-if="i.qtype == 1">
                <dt>
                  {{ index + 1 }}.{{ i.qname }}
                  <span v-if="i.not_null == 1" style="color: red">*</span>
                </dt>
                <dd v-if="i.have_result == 1">{{ i.result }}</dd>
                <dd v-if="i.have_result == 0">无</dd>
              </dl>
              <dl v-if="i.qtype == 2">
                <dt>
                  {{ index + 1 }}.{{ i.qname }}
                  <span v-if="i.not_null == 1" style="color: red">*</span>
                </dt>
                <dd v-if="i.have_result == 1">{{ i.result }}</dd>
                <dd v-if="i.have_result == 0">无</dd>
              </dl>
              <dl v-if="i.qtype == 3">
                <dt>
                  {{ index + 1 }}.{{ i.qname }}
                  <span style="color: red" v-if="i.not_null == 1">*</span>
                </dt>
                <ul v-if="i.have_result == 1">
                  <li v-for="item in i.result" :key="item">
                    <dd>{{ item }}</dd>
                  </li>
                </ul>
                <dd v-if="i.have_result == 0">无</dd>
              </dl>
              <div style="clear: both"></div>
            </div>
          </div>
        </el-dialog>
      </div>
    </template>
    <router-view />
  </div>
</template>

<script>
/**
 * @description: 调查表
 * @author: 陈莹莹
 * @update: 陈莹莹(2020-04-03 17：29)
 */

import pagination from '@cm/base/pagination'

import loading from '@/components/LoadGif'
export default {
  name: 'order',

  props: {
    naireId: String,
  },

  // mixins: [bg],
  data() {
    return {
      search: '',

      dialogTableVisible: false,

      orderlist: [],

      list: [],

      uphoto: '',

      uname: '',

      description: '',

      upuphotohoto: '',

      submit_time: '',

      naire_name: '',

      currentPage: 1,

      total: '',

      pageNum: '',

      // 显示加载图标
      isLoading: false,

      // 没有数据显示
      isNoData: false,

      key: '',

      //排序
      order: 0,
    }
  },

  components: {
    loading,
    // empty
    pagination,
  },

  watch: {
    search() {
      this.currentPage = 1
      this.getDate()
    },

    $route(to) {
      if (to.path === '/capitalOrder') {
        this.currentPage = 1
        this.getDate()
      }
    },
  },

  created() {
    this.naire_id = this.$route.query.naire_id || this.$route.query.id
    this.getDate()
  },

  methods: {
    exportTable() {
      if (this.total > 0) {
        this.$confirm(
          '确定要导出当前<strong>' + this.total + '</strong>条数据？',
          '提示',
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        ).then(() => {
          if (this.$route.query.id) {
            this.$store.dispatch(
              'open',
              `/PcSurvey/exportExecelNew?graphic_id=${this.naire_id}`
            )
          } else {
            this.$store.dispatch(
              'open',
              `/PcSurvey/exportExecelNew?naire_id=${this.naire_id}`
            )
          }
        })
      } else {
        this.$root.prompt('暂无数据，无法导出！')
      }
    },

    //查看
    detail(val) {
      this.dialogTableVisible = true
      this.$http({
        url: 'PcSurvey/resultDetail',
        data: {
          naire_id: this.naire_id,
          graphic_id: this.naire_id,
          submit_id: val,
        },
        callback: ({ data, code }) => {
          if (code == 200) {
            this.list = data.question_info
            this.uname = data.uname
            this.uphoto = data.uphoto
            this.description = data.description
            this.naire_name = data.naire_name
            this.submit_time = data.submit_time
          }
        },
        error: (error) => {
          if (error.code == 204) {
            this.list = []
          }
        },
      })
    },

    sortChange(val) {
      let or = String(val.order)
      if (or == 'descending') {
        this.order = 0
      } else {
        this.order = 1
      }
      this.getDate()
    },

    //停用弹窗
    stopt() {
      this.$confirm(
        '停用后，学员无需填写调查表，已填写会保留，是否停用？',
        '停用调查表',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      )
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },

    // currentPage 改变时会触发
    pageCodeChange(currentPage) {
      this.currentPage = currentPage
      this.getDate()
    },

    // 获取列表
    getDate() {
      this.isLoading = true
      this.$http({
        url: this.$route.query.id
          ? '/PcSurvey/getGraphicResultList'
          : 'PcSurvey/getResultList',
        data: {
          search: this.search,
          page: this.currentPage,
          naire_id: this.naire_id,
          graphic_id: this.naire_id,
          order: this.order,
        },
        callback: ({ data, code }) => {
          if (code == 200) {
            this.isNoData = false
            this.isLoading = false
            this.orderlist = data.result_info
            this.total = data.total
          }
        },
        error: (error) => {
          if (error.code == 204) {
            this.isNoData = true
            this.isLoading = false
            this.orderlist = []
            this.total = error.total
          }
        },
      })
    },
  },
}
</script>

<style lang="scss" scope>
#completedCadets {
  .all {
    padding: 20px 20px 60px 20px;
    background: rgba(255, 255, 255, 1);

    .search {
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    .clear {
      clear: both;
    }

    .btns {
      @extend %btns;
    }

    .table-contain {
      box-sizing: border-box;
    }

    .course-info {
      display: flex;
      align-items: center;

      .cover {
        width: 24px;
        height: 24px;
        position: relative;
        border-radius: 50%;

        .img {
          width: 100%;
          height: 100%;
        }
      }

      .right {
        flex: 1;
        overflow: hidden;
        margin-left: 8px;

        .title {
          font-size: 13px;
          font-weight: 500;
          line-height: 17px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: rgba(51, 51, 51, 1);
        }
      }
    }

    .pagination-wrap {
      padding: 30px 0;
      text-align: center;
    }

    .clear {
      clear: both;
    }

    .el-pagination {
      margin-top: 31px;
      margin-bottom: 46px;
    }

    .el-dialog__wrapper {
      .el-dialog {
        .zi1 {
          color: #666666ff;
          line-height: 20px;
          margin-right: 10px;
        }

        .tow {
          overflow: hidden;
          height: 42px;
          padding-top: 14px;
          border-bottom: 1px solid #e6e6e6ff;
          margin-bottom: 30px;

          .left {
            float: left;

            .tu1 {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              float: left;
              background-color: grey;
              margin-right: 10px;

              img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
              }
            }

            .zi2 {
              margin-top: 3px;
              font-size: 14px;
              font-weight: 500;
              color: rgba(51, 51, 51, 1);
              line-height: 17px;
              float: left;
            }
          }

          .right {
            float: right;
            font-size: 12px;
            color: rgba(153, 153, 153, 1);
            line-height: 16px;
          }
        }

        .auto {
          height: 463px;
          overflow-y: auto;

          .bottom {
            dt {
              margin-bottom: 10px;
              font-size: 15px;
              font-weight: bold;
              color: rgba(51, 51, 51, 1);
              line-height: 20px;
            }

            ul li {
              margin-right: 15px;
            }

            dd {
              margin-bottom: 25px;
              font-size: 13px;
              color: rgba(102, 102, 102, 1);
              line-height: 17px;
            }
          }
        }
      }
    }
  }
}
</style>
