<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-08-31 15:57:31
 * @LastEditTime: 2022-12-09 15:34:38
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @Description: 图文详情
 * @FilePath: \teacherdingding.com\src\views\graphic\components\detailGraphic.vue
-->
<template>
  <div class="detailGraphic">
    <div class="header">
      <div class="tu">
        <img :src="memberHeader.cover_img" class="img" alt="" />
      </div>
      <div class="right">
        <div class="title">
          <div class="hidetext" :title="memberHeader.name">
            {{ memberHeader.name }}
          </div>
          <div class="tip1" v-if="memberHeader.status == 1">已上架</div>
          <div class="tip2" v-else>已下架</div>
        </div>

        <div class="price_cross">
          <div class="price">
            <span v-if="memberHeader.price_type == 2">免费</span>
            <span v-else-if="memberHeader.price_type == 3">
              密码：{{ memberHeader.fee_pwd }}
            </span>
            <span v-else>￥{{ memberHeader.price }}</span>
          </div>
          <div
            class="cross"
            v-if="memberHeader.price_type == 1 && memberHeader.cost_price > 0"
          >
            {{ memberHeader.cost_price }}
          </div>
        </div>
        <div class="validty">
          有效期：
          <span v-if="memberHeader.validity_type == 1">永久有效</span>
          <span v-else-if="memberHeader.validity_type == 2">
            购买后{{ memberHeader.validity }}天有效
          </span>
          <span v-else>{{ memberHeader.validity | formatTimeStamp }}</span>
        </div>

        <div class="btns">
          <el-button
            type="primary"
            size="small"
            @click="
              $router.push({
                path: '/graphic/newGraphic',
                query: { id: graphic_id },
              })
            "
            style="width:100px"
          >
            编辑
          </el-button>
          <el-button
            type="primary"
            size="small"
            style="width:100px;margin-left:20px"
            @click="tuiguang()"
          >
            分享
          </el-button>
          <el-button
            type="primary"
            size="small"
            style="width:100px;margin-left:20px"
            @click="setStatus(2)"
            v-if="memberHeader.status == 1"
          >
            下架
          </el-button>

          <el-button
            v-else
            type="primary"
            @click="setStatus(1)"
            size="small"
            style="width:100px;margin-left:20px "
          >
            上架
          </el-button>

          <el-button
            type="primary"
            size="small"
            @click="del"
            style="width:100px;margin-left:20px "
          >
            删除
          </el-button>
        </div>
      </div>
    </div>
    <div class="main_contain">
      <el-tabs v-model="activeName">
        <el-tab-pane label="已购用户" name="1"></el-tab-pane>
        <el-tab-pane label="已售订单" name="2"></el-tab-pane>
        <el-tab-pane label="信息采集结果" name="3"></el-tab-pane>
      </el-tabs>

      <div class="tablist">
        <member-list
          v-if="activeName == 1"
          :graphic_id="graphic_id"
          :memberHeader="memberHeader"
        ></member-list>
        <sale-order
          v-if="activeName == 2"
          :graphic_id="graphic_id"
          :memberHeader="memberHeader"
        ></sale-order>
        <completedCadets v-if="activeName == 3"></completedCadets>
      </div>
    </div>

    <!-- 推广 -->
    <extension
      v-if="status"
      :selectrow="memberHeader"
      :dialogstatus.sync="status"
    ></extension>
  </div>
</template>

<script>
import memberList from './memberList'
import saleOrder from './saleOrder'
import extension from './extension'
import completedCadets from '@/views/Survey/completedCadets'
export default {
  name: 'detailGraphic',

  components: {
    extension,
    completedCadets,
    memberList,
    saleOrder,
  },

  data() {
    return {
      status: false,

      memberHeader: {},

      activeName: '1',
    }
  },

  computed: {
    graphic_id() {
      return this.$route.query.id
    },
  },

  created() {
    this.getMemberHeader()
  },

  methods: {
    //分享
    tuiguang(row) {
      this.selectrow = row
      this.$nextTick(() => {
        this.status = true
      })
    },

    // 上下架
    async setStatus(val) {
      if (val == 2) {
        this.$confirm('是否确定下架该图文？', '下架', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then(async () => {
            await this.setfn(val)
          })
          .catch(() => {})
      } else {
        await this.setfn(val)
      }
    },

    async setfn(val) {
      await this.$http({
        url: '/Graphic/setStatus',
        data: {
          graphic_id: this.graphic_id,
          status: val,
        },
        successMsg: true,
      })
      this.getMemberHeader()
    },

    // 删除
    async del() {
      this.$confirm('是否确定删除该图文？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/Graphic/delGraphic',
            data: {
              graphic_id: this.graphic_id,
            },
            successMsg: true,
          })
          this.$router.go(-1)
        })
        .catch(() => {})
    },

    async getMemberHeader() {
      const { data } = await this.$http({
        url: '/Graphic/header',
        data: {
          graphic_id: this.graphic_id,
        },
      })
      this.memberHeader = data
    },
  },
}
</script>

<style lang="scss" scoped>
.detailGraphic {
  .header {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 16px;
    margin-bottom: 20px;
    .tu {
      width: 228px;
      height: 138px;
      margin-right: 20px;
      position: relative;
      flex-shrink: 0;
      //   top: 35px;
      //   left: 18px;
      border-radius: 6px;
      .name {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 167px;
        font-size: 15px;
        .sub_title {
          font-size: 13px;
          margin: 3px 0 0px;
        }
      }
      .quanyi {
        margin-top: 15px;
      }
      .img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
    .right {
      line-height: 1.5;
      flex: 1;
      overflow: hidden;
      .price_cross {
        display: flex;
        align-items: center;
        margin-bottom: 9px;
        margin-top: 5px;
        .price {
          font-size: 14px;
          color: #ff3535;
          margin-right: 10px;
        }
        .cross {
          font-size: 14px;
          color: #a1a1a1;
          text-decoration: line-through;
        }
      }
      .validty {
        font-size: 12px;
        color: #666666;
        margin: 5px 0 20px;
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        display: flex;
        align-items: center;
        .tip1 {
          font-size: 12px;
          color: #0aa29b;
          line-height: 12px;
          padding: 3px 5px;
          border: 1px solid #0aa29b;
          margin-left: 6px;
          flex-shrink: 0;
        }
        .tip2 {
          flex-shrink: 0;
          font-size: 12px;
          color: #ff3535;
          line-height: 12px;
          padding: 3px 5px;
          border: 1px solid #ff3535;
          margin-left: 6px;
        }
      }
    }
  }
  ::v-deep .main_contain {
    background-color: #fff;
    .el-tabs__nav-scroll {
      padding: 0 20px;
    }
    .el-tabs__nav-wrap::after {
      height: 1px;
    }

    .tablist {
      padding: 0 20px 20px;
    }
  }
}
</style>
